import axios from 'axios';
import { instance as axiosInstance } from 'src/utils/axios';

export const cancelSource = axios.CancelToken.source();

export const getAllTalents = (queryParams = {}) => new Promise((resolve, reject) => {
  const {
    firstname, lastname, email, page, limit,
    percentile,
    assessmentIds,
    salaryExpectation,
    countries
  } = queryParams;

  axiosInstance
    .get('/talent', {
      params: {
        firstname,
        lastname,
        email,
        percentile,
        assessmentIds,
        salaryExpectation,
        countries,
        page,
        limit
      },
      cancelToken: cancelSource.token
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
});

export const getTalent = id => new Promise((resolve, reject) => {
  axiosInstance
    .get(`/talent/${id}`, { cancelToken: cancelSource.token })
    .then(response => resolve(response.data.talent))
    .catch(error => reject(error));
});

export const getTalentAssessmentHistory = id => new Promise((resolve, reject) => {
  axiosInstance
    .get(`/talent/${id}/assessmentHistory`, {
      cancelToken: cancelSource.token
    })
    .then(response => {
      resolve(response.data.talentAssessments);
    })
    .catch(error => reject(error));
});
