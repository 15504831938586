import { instance as axiosInstance } from 'src/utils/axios';

export const getAllSkills = () => new Promise((resolve, reject) => {
  axiosInstance.get('/skill/all')
    .then(response => {
      resolve(response.data.skills);
    })
    .catch(error => {
      reject(error);
    });
});

export const getSkill = skillId => new Promise((resolve, reject) => {
  axiosInstance.get(`/skill/${skillId}`)
    .then(response => {
      resolve(response.data.skill);
    })
    .catch(error => {
      reject(error);
    });
});
