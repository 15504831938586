/* eslint-disable no-param-reassign */
import produce from 'immer';

import { GET_SKILLS_FAILURE, GET_SKILLS_REQUEST, GET_SKILLS_SUCCESS } from 'src/actions/skillActions';

const initialState = {
  skills: [],
  isFetchingSkills: false,
  error: null
};

const skillReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SKILLS_REQUEST: {
      return produce(state, draft => {
        draft.isFetchingSkills = true;
      });
    }

    case GET_SKILLS_SUCCESS: {
      return produce(state, draft => {
        const { data } = action.payload;

        draft.isFetchingSkills = false;
        draft.skills = data;
      });
    }

    case GET_SKILLS_FAILURE: {
      return produce(state, draft => {
        const { error } = action.payload;

        draft.isFetchingSkills = false;
        draft.error = error;
      });
    }

    default: {
      return state;
    }
  }
};

export default skillReducer;
