/* eslint-disable no-param-reassign */
import produce from 'immer';

import {
  CLEAR_TALENTS_FILTERS,
  UPDATE_TALENTS_FILTER_KEY_VALUE,
  GET_FILTERED_TALENTS_SUCCESS,
  GET_FILTERED_TALENTS_FAILURE,
  GET_FILTERED_TALENTS_REQUEST
} from 'src/actions/talentActions';

const initialState = {
  talents: [],
  isFetchingTalents: false,
  pagination: {
    per_page: 10,
    current_page: 0,
    total: -1
  },
  filters: {
    firstname: '',
    lastname: '',
    email: '',
    percentile: '',
    salaryExpectation: '',
    assessmentIds: [],
    countries: [],
    page: 1,
    limit: 100
  },
  error: null
};

const talentReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_TALENTS_FILTERS: {
      return produce(state, draft => {
        draft.filters = initialState.filters;
      });
    }
    case UPDATE_TALENTS_FILTER_KEY_VALUE: {
      const { key, value } = action.payload;
      return produce(state, draft => {
        draft.filters[key] = value;
      });
    }
    case GET_FILTERED_TALENTS_REQUEST: {
      return produce(state, draft => {
        draft.isFetchingTalents = true;
      });
    }
    case GET_FILTERED_TALENTS_SUCCESS: {
      const { data, pagination } = action.payload;

      return produce(state, draft => {
        draft.talents = data;
        draft.pagination = pagination;
        draft.isFetchingTalents = false;
      });
    }
    case GET_FILTERED_TALENTS_FAILURE: {
      const { error } = action.payload;
      return produce(state, draft => {
        draft.error = error;
        draft.talents = [];
        draft.isFetchingTalents = false;
      });
    }
    default: {
      return state;
    }
  }
};

export default talentReducer;
