/* eslint-disable import/prefer-default-export */
export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const QUESTION_TYPES = {
  mcq: 'Multiple Choice',
  checkboxes: 'Checkbox',
  algorithm: 'Algorithm',
};

export const DB_QUERY_CONDITIONS = {
  any: 'Any',
  exact: 'Exact'
};

export const DIFFICULTY_LEVELS = {
  1: 'Basic',
  2: 'Intermediate',
  3: 'Advanced',
  4: 'Expert'
};

export const ASSESSMENT_TYPES = {
  mcq: 'Multiple Choice'
};

export const QUESTIONS_FILTER_KEYS = {
  LEVEL: 'level',
  SELECTED_SKILLS_FILTER: 'selectedSkillsFilter',
  SELECTED_ROLES_FILTER: 'selectedRolesFilter',
  TYPE: 'type',
  TITLE: 'title',
  DESCRIPTION: 'description',
  QUESTIONID: 'questionId',
  ROLES_CONDITION: 'roleIdOperator',
  SKILLS_CONDITION: 'skillIdOperator'
};

export const NEW_SIGNUP_GROUPS = {
  1: 'Days',
  2: 'Weeks',
  3: 'Months',
  4: 'Quarters',
  5: 'Years'
};

export const NEW_SIGNUP_GROUPS_TO_GRAPH = {
  1: 'dailyData',
  2: 'weekData',
  3: 'monthData',
  4: 'quarterData',
  5: 'yearData'
};
