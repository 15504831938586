import { instance as axiosInstance } from 'src/utils/axios';
import { convertTimeToSeconds } from 'src/utils/time';
import {
  checkboxesAnswersSchema,
  checkboxesCorrectAnswerSchema,
  extractAnswersFromSchema,
  mcqAnswersSchema,
  mcqCorrectAnswerSchema,
  parseCorrectAnswerFromSchema
} from 'src/utils/schema';

export const getQuestions = () => new Promise((resolve, reject) => {
  axiosInstance
    .get('/question/all')
    .then(response => {
      const { questions } = response.data;

      // transform question answers since they are in jsonSchema format
      const transformedQuestions = questions.map(question => {
        let { answers } = question;
        if (answers) {
          answers = extractAnswersFromSchema(answers);
        }

        let { correct_answer } = question;
        if (correct_answer) {
          // eslint-disable-next-line
          correct_answer = parseCorrectAnswerFromSchema(
            correct_answer
          );
        }

        return { ...question, answers, correct_answer };
      });

      resolve(transformedQuestions);
    })
    .catch(error => {
      reject(error);
    });
});

export const getFilteredQuestions = ({
  level,
  type,
  role_id,
  role_id_operator,
  skill_id,
  skill_id_operator,
  title,
  description,
  id
}) => new Promise((resolve, reject) => {
  axiosInstance
    .get('/question', {
      params: {
        level,
        type,
        role_id,
        role_id_operator,
        skill_id,
        skill_id_operator,
        title,
        description,
        id
      }
    })
    .then(response => {
      const { questions } = response.data;

      // transform question answers since they are in jsonSchema format
      const transformedQuestions = questions.map(question => {
        let { answers } = question;
        if (answers) {
          answers = extractAnswersFromSchema(answers);
        }

        let { correct_answer } = question;
        if (correct_answer) {
          // eslint-disable-next-line
          correct_answer = parseCorrectAnswerFromSchema(
            correct_answer
          );
        }

        return { ...question, answers, correct_answer };
      });

      resolve(transformedQuestions);
    })
    .catch(error => {
      reject(error);
    });
});

export const getQuestion = questionId => new Promise((resolve, reject) => {
  axiosInstance
    .get(`/question/${questionId}`)
    .then(response => {
      const { question } = response.data;

      const parsedQuestion = { ...question };
      // eslint-disable-next-line
      const { answers, correct_answer } = { ...parsedQuestion };

      if (answers) {
        parsedQuestion.answers = extractAnswersFromSchema(answers);
      }
      if (correct_answer) {
        parsedQuestion.correct_answer = parseCorrectAnswerFromSchema(
          correct_answer
        );
      }

      resolve(parsedQuestion);
    })
    .catch(error => {
      reject(error);
    });
});

function getAlgorithmPayload(values) {
  const {
    question,
    questionType,
    difficulty,
    duration,
    durationUnit,
    questionRoles,
    questionSkills,
    description,
    correctAnswerScore,
    wrongAnswerScore,
    testCases,
    starterCodes,
    problemStatement,
    sampleInput,
    sampleOutput,
    constraints,
    explanation,
    inputFormat,
    outputFormat,
    maxRuntimeLimit,
    maxMemoryLimit,
  } = values;

  const time = convertTimeToSeconds(duration, durationUnit);
  const roles = questionRoles.map(role => role.id);
  const skills = questionSkills.map(skill => skill.id);

  return {
    title: question,
    type: questionType,
    level: difficulty,
    time,
    description,
    correctAnswerScore,
    wrongAnswerScore,
    roles,
    skills,
    testCases,
    starterCodes,
    problemStatement,
    sampleInput,
    sampleOutput,
    constraints,
    explanation,
    inputFormat,
    outputFormat,
    maxRuntimeLimit,
    maxMemoryLimit,
  };
}

export const createQuestion = questionValues => new Promise((resolve, reject) => {
  const {
    question,
    questionType,
    difficulty,
    correctAnswerScore,
    wrongAnswerScore,
    duration,
    durationUnit,
    description,
    answers,
    neutralAnswers,
    correctAnswer,
    questionRoles,
    questionSkills,
    resources,
  } = questionValues;

  const time = convertTimeToSeconds(duration, durationUnit);
  const roles = questionRoles.map(role => role.id);
  const skills = questionSkills.map(skill => skill.id);
  let answersSchema;
  let correctAnswerSchema;

  switch (questionType) {
    case 'mcq':
      answersSchema = mcqAnswersSchema(answers);
      correctAnswerSchema = mcqCorrectAnswerSchema(
        correctAnswer,
        neutralAnswers
      );
      break;
    case 'checkboxes':
      answersSchema = checkboxesAnswersSchema(answers);
      correctAnswerSchema = checkboxesCorrectAnswerSchema(
        correctAnswer,
        neutralAnswers
      );
      break;
    default:
      break;
  }

  let payload = null;
  if (questionType === 'algorithm') {
    payload = getAlgorithmPayload(questionValues);
  } else {
    payload = {
      title: question,
      type: questionType,
      level: difficulty,
      answers: answersSchema,
      correctAnswer: correctAnswerSchema,
      time,
      description,
      correctAnswerScore,
      wrongAnswerScore,
      roles,
      skills,
      resources,
    };
  }

  axiosInstance
    .post('/question/create', payload)
    .then(response => {
      resolve(response.data.question);
    })
    .catch(error => {
      reject(error);
    });
});

export const editQuestion = async (
  questionId,
  questionValues,
  isLiveQuestion = false
) => {
  const {
    question,
    questionType,
    difficulty,
    correctAnswerScore,
    wrongAnswerScore,
    duration,
    durationUnit,
    description,
    answers,
    neutralAnswers,
    correctAnswer,
    questionRoles,
    questionSkills,
    resources,
  } = questionValues;

  const time = convertTimeToSeconds(duration, durationUnit);
  const roles = questionRoles.map(role => role.id);
  const skills = questionSkills.map(skill => skill.id);
  let answersSchema;
  let correctAnswerSchema;

  const responseResult = {
    question: {},
    roles: {},
    skills: {}
  };

  switch (questionType) {
    case 'mcq':
      answersSchema = mcqAnswersSchema(answers);
      correctAnswerSchema = mcqCorrectAnswerSchema(
        correctAnswer,
        neutralAnswers
      );
      break;
    case 'checkboxes':
      answersSchema = checkboxesAnswersSchema(answers);
      correctAnswerSchema = checkboxesCorrectAnswerSchema(
        correctAnswer,
        neutralAnswers
      );
      break;
    default:
      break;
  }

  let payload = null;
  if (questionType === 'algorithm') {
    payload = getAlgorithmPayload(questionValues);
  } else {
    payload = {
      title: question,
      type: questionType,
      level: difficulty,
      answers: answersSchema,
      correctAnswer: JSON.stringify(correctAnswerSchema),
      time,
      description,
      correctAnswerScore,
      wrongAnswerScore,
    };
  }

  try {
    if (isLiveQuestion) {
      const liveQuestionResponse = await axiosInstance.put(
        `/question/${questionId}/live`,
        payload
      );
      responseResult.question = liveQuestionResponse.data.question;
    } else {
      const questionResponse = await axiosInstance.put(
        `/question/${questionId}`,
        payload
      );
      responseResult.question = questionResponse.data.question;
    }
    const questionRolesResponse = await axiosInstance.put(
      `question/${questionId}/updateRoles`,
      { roles }
    );
    const questionSkillsResponse = await axiosInstance.put(
      `question/${questionId}/updateSkills`,
      { skills }
    );
    const questionResourcesResponse = await axiosInstance.put(
      `question/${questionId}/updateresources`,
      { resources }
    );

    responseResult.roles = questionRolesResponse.data.roles;
    responseResult.skills = questionSkillsResponse.data.skills;
    responseResult.resources = questionResourcesResponse.data.resources;

    return responseResult;
  } catch (error) {
    return error;
  }
};
