import axios from 'axios';

import { API_BASE_URL } from 'src/config';

export const instance = axios.create({
  baseURL: API_BASE_URL
});

export const cancelTokenSource = axios.CancelToken.source();

export default axios;
