/* eslint-disable no-param-reassign */
import produce from 'immer';

import { GET_ROLES_FAILURE, GET_ROLES_REQUEST, GET_ROLES_SUCCESS } from 'src/actions/roleActions';

const initialState = {
  roles: [],
  isFetchingRoles: false,
  error: null
};

const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROLES_REQUEST: {
      return produce(state, draft => {
        draft.isFetchingRoles = true;
      });
    }

    case GET_ROLES_SUCCESS: {
      return produce(state, draft => {
        const { data } = action.payload;

        draft.isFetchingRoles = false;
        draft.roles = data;
      });
    }

    case GET_ROLES_FAILURE: {
      return produce(state, draft => {
        const { error } = action.payload;

        draft.isFetchingRoles = false;
        draft.error = error;
      });
    }

    default: {
      return state;
    }
  }
};

export default roleReducer;
