import { instance as axios } from 'src/utils/axios';

class AccountService {
  setAxiosInterceptors({ onLogout }) {
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      return;
    }

    this.setSession(accessToken);
  }

  loginWithEmailAndPassword(email, password) {
    return new Promise((resolve, reject) => {
      axios
        .post('/login', { email, password })
        .then(response => {
          if (response.data.token) {
            this.setSession(response.data.token);
            resolve(true);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  registerAdmin({firstName, lastName, email, password}) { 
    return new Promise(function(resolve, reject) {
      const accessToken = localStorage.getItem('skilleddAccessToken');

      axios
        .post(
          '/admin/signup',
          {
            firstName,
            lastName,
            email,
            password
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          if (error.response) reject(error.response.data.errors);
          else reject(error);
        });
    });
  };

  loginWithToken() {
    return new Promise((resolve, reject) => {
      const accessToken = localStorage.getItem('skilleddAccessToken');

      if (!accessToken) {
        reject();
      } else {
        resolve(true);
      }
    });
  };

  logout() {
    this.setSession(null);
    this.setRefreshToken(null);
  };

  getProfile() { 
    return new Promise((resolve, reject) => {
      const accessToken = localStorage.getItem('skilleddAccessToken');

      if (!accessToken) {
        reject();
      } else {
        axios
          .get('/admin/me', {
            headers: { Authorization: `Bearer ${accessToken}` }
          })
          .then(response => {
            resolve(response.data.admin);
          })
          .catch(error => {
            reject(error);
          });
      }
    })
  };

  setSession(accessToken) {
    if (accessToken) {
      localStorage.setItem('skilleddAccessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('skilleddAccessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  setRefreshToken(refreshToken) {
    if (refreshToken) {
      localStorage.setItem('refreshToken', refreshToken);
    } else {
      localStorage.removeItem('refreshToken');
    }
  };

  getAccessToken() { return localStorage.getItem('skilleddAccessToken') };

  isAuthenticated() { return !!this.getAccessToken() };
}

const accountService = new AccountService();

export default accountService;
