export const mcqAnswersSchema = answers => {
  const uiSchema = {
    field: {
      'ui:widget': 'radio'
    }
  };

  const jsonSchema = {
    type: 'object',
    required: ['field'],
    properties: {
      field: {
        type: 'string',
        enum: answers.map((answer, index) => String.fromCharCode(65 + index)),
        enumNames: [...answers],
        uniqueItems: true
      }
    }
  };

  return { jsonSchema, uiSchema };
};

export const mcqCorrectAnswerSchema = (correctAnswer, neutralAnswers) => {
  const field = {
    correctAnswer,
    neutralAnswer: [...neutralAnswers]
  };

  return { field };
};

export const checkboxesAnswersSchema = answers => {
  const uiSchema = {
    field: {
      'ui:widget': 'checkboxes'
    }
  };

  const jsonSchema = {
    type: 'object',
    required: ['field'],
    properties: {
      field: {
        type: 'string',
        enum: answers.map((answer, index) => String.fromCharCode(65 + index)),
        enumNames: [...answers],
        uniqueItems: true
      }
    }
  };

  return { jsonSchema, uiSchema };
};

export const checkboxesCorrectAnswerSchema = (
  correctAnswer,
  neutralAnswers
) => {
  const field = {
    correctAnswer: [...correctAnswer],
    neutralAnswer: [...neutralAnswers]
  };

  return { field };
};

export const extractAnswersFromSchema = answers => {
  const { jsonSchema } = answers;
  const { enum: enums, enumNames } = jsonSchema.properties.field;

  return { enums, enumNames };
};

export const parseCorrectAnswerFromSchema = correctAnswerJson => {
  const parsedCorrectAnswer = JSON.parse(correctAnswerJson);
  const { correctAnswer, neutralAnswer } = parsedCorrectAnswer.field;

  return { correctAnswer, neutralAnswer };
};
