/* eslint-disable no-param-reassign */
import produce from 'immer';

import {
  CLEAR_QUESTION,
  GET_FILTERED_QUESTIONS_SUCCESS,
  GET_FILTERED_QUESTIONS_REQUEST,
  GET_FILTERED_QUESTIONS_FAILURE,
  GET_QUESTIONS_FAILURE,
  GET_QUESTIONS_REQUEST,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTION_FAILURE,
  GET_QUESTION_REQUEST,
  GET_QUESTION_SUCCESS,
  CLEAR_QUESTIONS_FILTERS,
  UPDATE_QUESTIONS_FILTER_KEY_VALUE,
} from 'src/actions/questionActions';
import { DIFFICULTY_LEVELS, DB_QUERY_CONDITIONS } from 'src/constants';

const initialState = {
  question: null,
  questions: [],
  isFetchingQuestion: false,
  isFetchingQuestions: false,
  isFetchingFilteredQuestions: false,
  filters: {
    level: [...Object.entries(DIFFICULTY_LEVELS)],
    type: 'all',
    selectedSkillsFilter: [],
    selectedRolesFilter: [],
    roleIdOperator: DB_QUERY_CONDITIONS.any.toLowerCase(),
    skillIdOperator: DB_QUERY_CONDITIONS.any.toLowerCase(),
    title: '',
    description: '',
    questionId: '',
  },
  error: null
};

const questionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUESTIONS_REQUEST: {
      return produce(state, draft => {
        draft.isFetchingQuestions = true;
      });
    }
    case GET_QUESTIONS_SUCCESS: {
      const { data } = action.payload;

      return produce(state, draft => {
        draft.questions = data;
        draft.isFetchingQuestions = false;
      });
    }
    case GET_QUESTIONS_FAILURE: {
      const { error } = action.payload;

      return produce(state, draft => {
        draft.error = error;
        draft.questions = null;
        draft.isFetchingQuestions = false;
      });
    }
    case GET_FILTERED_QUESTIONS_REQUEST: {
      return produce(state, draft => {
        draft.isFetchingFilteredQuestions = true;
      });
    }
    case GET_FILTERED_QUESTIONS_SUCCESS: {
      const { data } = action.payload;

      return produce(state, draft => {
        draft.questions = data;
        draft.isFetchingFilteredQuestions = false;
      });
    }
    case GET_FILTERED_QUESTIONS_FAILURE: {
      const { error } = action.payload;

      return produce(state, draft => {
        draft.error = error;
        draft.questions = null;
        draft.isFetchingFilteredQuestions = false;
      });
    }
    case CLEAR_QUESTIONS_FILTERS: {
      return produce(state, draft => {
        draft.filters = initialState.filters;
      });
    }
    case UPDATE_QUESTIONS_FILTER_KEY_VALUE: {
      const { key, value } = action.payload;
      return produce(state, draft => {
        draft.filters[key] = value;
      });
    }
    case GET_QUESTION_REQUEST: {
      return produce(state, draft => {
        draft.isFetchingQuestion = true;
      });
    }
    case GET_QUESTION_SUCCESS: {
      const { data } = action.payload;

      return produce(state, draft => {
        draft.question = data;
        draft.isFetchingQuestion = false;
      });
    }
    case GET_QUESTION_FAILURE: {
      const { error } = action.payload;

      return produce(state, draft => {
        draft.error = error;
        draft.question = null;
        draft.isFetchingQuestion = false;
      });
    }
    case CLEAR_QUESTION: {
      return produce(state, draft => {
        draft.question = null;
      });
    }
    default: {
      return state;
    }
  }
};

export default questionReducer;
