import * as roleApi from 'src/services/roleService';

export const GET_ROLES_REQUEST = '@role/get-roles-request';
export const GET_ROLES_SUCCESS = '@role/get-roles-success';
export const GET_ROLES_FAILURE = '@role/get-roles-failure';

export const GET_ROLE_REQUEST = '@role/get-role-request';
export const GET_ROLE_SUCCESS = '@role/get-role-success';
export const GET_ROLE_FAILURE = '@role/get-role-failure';

export function getRoles() {
  return async dispatch => {
    try {
      dispatch({ type: GET_ROLES_REQUEST });

      const roles = await roleApi.getAllRoles();

      dispatch({
        type: GET_ROLES_SUCCESS,
        payload: {
          data: roles.sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
          })
        }
      });
    } catch (error) {
      dispatch({
        type: GET_ROLES_FAILURE,
        payload: { error }
      });
    }
  };
}

export function getRole(roleId) {
  return async dispatch => {
    try {
      dispatch({ type: GET_ROLE_REQUEST });

      const role = await roleApi.getRole(roleId);

      dispatch({
        type: GET_ROLE_SUCCESS,
        payload: {
          data: role
        }
      });
    } catch (error) {
      dispatch({
        type: GET_ROLE_FAILURE,
        payload: { error }
      });
    }
  };
}
