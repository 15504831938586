import React from 'react';
import { useHistory } from 'react-router-dom';

import { IconButton, SvgIcon, Tooltip } from '@material-ui/core';
import { PersonAdd as PersonAddIcon } from '@material-ui/icons';

function AddUser() {
  const history = useHistory();

  const handleClick = () => {
    history.push('/app/users/add');
  };

  return (
    <Tooltip title="Add new admin">
      <IconButton color="inherit" onClick={handleClick}>
        <SvgIcon fontSize="small">
          <PersonAddIcon />
        </SvgIcon>
      </IconButton>
    </Tooltip>
  );
}

export default AddUser;
