import * as questionApi from 'src/services/questionService';

export const GET_QUESTIONS_SUCCESS = '@question/get-questions-success';
export const GET_QUESTIONS_REQUEST = '@question/get-questions-request';
export const GET_QUESTIONS_FAILURE = '@question/get-questions-failure';

export const GET_FILTERED_QUESTIONS_SUCCESS = '@question/get-questions-success';
export const GET_FILTERED_QUESTIONS_REQUEST = '@question/get-questions-request';
export const GET_FILTERED_QUESTIONS_FAILURE = '@question/get-questions-failure';

export const GET_QUESTION_SUCCESS = '@question/get-question-success';
export const GET_QUESTION_REQUEST = '@question/get-question-request';
export const GET_QUESTION_FAILURE = '@question/get-question-failure';

export const CLEAR_QUESTION = '@question/clear-question';

export const CLEAR_QUESTIONS_FILTERS = '@question/clear-questions-filters';
export const UPDATE_QUESTIONS_FILTER_KEY_VALUE = '@question/update-questions-filter-key-value';

export function getQuestions() {
  return async dispatch => {
    try {
      dispatch({ type: GET_QUESTIONS_REQUEST });

      const questions = await questionApi.getQuestions();

      dispatch({
        type: GET_QUESTIONS_SUCCESS,
        payload: {
          data: questions
        }
      });
    } catch (error) {
      dispatch({
        type: GET_QUESTIONS_FAILURE,
        payload: { error }
      });
    }
  };
}

export function getFilteredQuestions({
  level,
  type,
  id,
  role_id,
  role_id_operator,
  skill_id,
  skill_id_operator,
  title,
  description
}) {
  return async dispatch => {
    try {
      dispatch({ type: GET_FILTERED_QUESTIONS_REQUEST });

      const filteredQuestions = await questionApi.getFilteredQuestions({
        level,
        type,
        id,
        role_id,
        role_id_operator,
        skill_id,
        skill_id_operator,
        title,
        description
      });

      dispatch({
        type: GET_FILTERED_QUESTIONS_SUCCESS,
        payload: {
          data: filteredQuestions
        }
      });
    } catch (error) {
      dispatch({
        type: GET_FILTERED_QUESTIONS_FAILURE,
        payload: { error }
      });
    }
  };
}

export function getQuestion(questionId) {
  return async dispatch => {
    try {
      dispatch({ type: GET_QUESTION_REQUEST });

      const question = await questionApi.getQuestion(questionId);

      dispatch({
        type: GET_QUESTION_SUCCESS,
        payload: {
          data: question
        }
      });

      return question;
    } catch (error) {
      dispatch({
        type: GET_QUESTION_FAILURE,
        payload: {
          error
        }
      });
    }
  };
}

export function updateQuestionsFilterKeyValue(key, value) {
  return {
    type: UPDATE_QUESTIONS_FILTER_KEY_VALUE,
    payload: {
      key,
      value
    }
  };
}

export function clearQuestionsFilters() {
  return {
    type: CLEAR_QUESTIONS_FILTERS,
  };
}

export function clearQuestion() {
  return {
    type: CLEAR_QUESTION
  };
}
