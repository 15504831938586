/* eslint-disable no-param-reassign */
import produce from 'immer';

import {
  GET_ASSESSMENT_REQUEST,
  GET_ASSESSMENT_SUCCESS,
  GET_ASSESSMENT_FAILURE,
  SELECT_ASSESSMENT,
  GET_ASSESSMENTS_REQUEST,
  GET_ASSESSMENTS_SUCCESS,
  GET_ASSESSMENTS_FAILURE,
  CLEAR_ASSESSMENT,
} from 'src/actions/assessmentActions';

const initialState = {
  assessments: [],
  selectedQuestions: [],
  selectedAssessment: null,
  selectedAssessmentId: null,
  isFetchingAssessment: false,
  isFetchingAssessments: false,
  error: null
};

const assessmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSESSMENTS_REQUEST: {
      return produce(state, draft => {
        draft.isFetchingAssessments = true;
      });
    }

    case GET_ASSESSMENTS_SUCCESS: {
      const { data } = action.payload;

      return produce(state, draft => {
        draft.assessments = data;
        draft.isFetchingAssessments = false;
      });
    }

    case GET_ASSESSMENTS_FAILURE: {
      const { error } = action.payload;
      // Maybe handle error payload
      return produce(state, draft => {
        draft.isFetchingAssessments = false;
        draft.assessments = [];
        draft.error = error;
      });
    }

    case GET_ASSESSMENT_REQUEST: {
      return produce(state, draft => {
        draft.isFetchingAssessment = true;
      });
    }

    case GET_ASSESSMENT_SUCCESS: {
      const { data } = action.payload;

      return produce(state, draft => {
        draft.selectedAssessment = data;
        draft.isFetchingAssessment = false;
      });
    }

    case GET_ASSESSMENT_FAILURE: {
      const { error } = action.payload;
      // Maybe handle error payload
      return produce(state, draft => {
        draft.isFetchingAssessment = false;
        draft.selectedAssessment = null;
        draft.error = error;
      });
    }

    case SELECT_ASSESSMENT: {
      const { assessmentId } = action.payload;

      return produce(state, draft => {
        draft.selectedAssessmentId = assessmentId;
      });
    }

    case CLEAR_ASSESSMENT: {
      return produce(state, draft => {
        draft.selectedAssessment = null;
      });
    }

    default: {
      return state;
    }
  }
};

export default assessmentReducer;
