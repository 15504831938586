import accountService from 'src/services/accountService';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const PROFILE_REQUEST = '@account/profile-request';
export const PROFILE_SUCCESS = '@account/profile-success';
export const PROFILE_ERROR = '@account/profile-error';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';

export function login(email, password) {
  return async dispatch => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      const user = await accountService.loginWithEmailAndPassword(
        email,
        password
      );

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function getProfile() {
  return async dispatch => {
    try {
      dispatch({ type: PROFILE_REQUEST });
      const admin = await accountService.getProfile();

      dispatch({
        type: PROFILE_SUCCESS,
        payload: {
          admin
        }
      });
    } catch (error) {
      dispatch({ type: PROFILE_ERROR });
      throw error;
    }
  };
}

export function setUserData(isAuthenticated) {
  return dispatch => dispatch({
    type: SILENT_LOGIN,
    payload: {
      isAuthenticated
    }
  });
}

export function logout() {
  return async dispatch => {
    accountService.logout();

    dispatch({
      type: LOGOUT
    });
  };
}

export function register(payload) {
  return async dispatch => {
    await accountService.registerAdmin(payload);
    dispatch({
      type: REGISTER
    });
  };
}
