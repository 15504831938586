import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Typography,
  ListItem,
  SvgIcon
} from '@material-ui/core';
import {
  Menu as MenuIcon
} from '@material-ui/icons';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import AddUser from './AddUser';
import Account from './Account';
import Settings from './Settings';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.main
      }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
        backgroundColor: theme.palette.background.default
      }
      : {})
  },
  toolbar: {
    minHeight: 64
  },
  navMenu: {
    display: 'flex'
  },
  navItem: {
    marginRight: theme.typography.pxToRem(12)
  },
  navIcon: {
    marginRight: theme.typography.pxToRem(4)
  },
  navLink: {
    textDecoration: 'none'
  },
  navText: {
    color: 'white',
    fontSize: theme.typography.pxToRem(18),
    '&:hover': {
      color: 'rgb(248,248,255)'
    }
  }
}));

function NavItem({ icon: Icon, nav }) {
  const classes = useStyles();

  return (
    <ListItem className={classes.navItem} key={nav.id}>
      <Icon className={classes.navIcon} />
      <RouterLink to={nav.href} className={classes.navLink}>
        <Typography className={classes.navText}>{nav.title}</Typography>
      </RouterLink>
    </ListItem>
  );
}

function TopBar({ className, onMobileNavOpen, ...rest }) {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <AddUser />
        <Box ml={1}>
          <Settings />
        </Box>
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};
NavItem.propTypes = {
  icon: PropTypes.elementType,
  nav: PropTypes.object
};

export default TopBar;
