import { instance as axiosInstance } from 'src/utils/axios';

export const getAllRoles = () => new Promise((resolve, reject) => {
  axiosInstance.get('/role/all')
    .then(response => {
      resolve(response.data.roles);
    })
    .catch(error => {
      reject(error);
    });
});

export const getRole = roleId => new Promise((resolve, reject) => {
  axiosInstance.get(`/role/${roleId}`)
    .then(response => {
      resolve(response.data.role);
    })
    .catch(error => {
      reject(error);
    });
});
