import * as assessmentApi from 'src/services/assessmentService';

export const GET_ASSESSMENTS_SUCCESS = '@assessment/get-assessments-success';
export const GET_ASSESSMENTS_REQUEST = '@assessment/get-assessments-request';
export const GET_ASSESSMENTS_FAILURE = '@assessment/get-assessments-failure';

export const GET_ASSESSMENT_SUCCESS = '@assessment/get-assessment-success';
export const GET_ASSESSMENT_REQUEST = '@assessment/get-assessment-request';
export const GET_ASSESSMENT_FAILURE = '@assessment/get-assessment-failure';

export const SELECT_ASSESSMENT = '@assessment/select-assessment';
export const CLEAR_ASSESSMENT = '@assessment/clear-assessment';

export function getAssessments() {
  return async dispatch => {
    try {
      dispatch({ type: GET_ASSESSMENTS_REQUEST });

      const assessments = await assessmentApi.getAssessments();

      dispatch({
        type: GET_ASSESSMENTS_SUCCESS,
        payload: {
          data: assessments
        }
      });
    } catch (error) {
      dispatch({ type: GET_ASSESSMENTS_FAILURE, payload: { error } });
    }
  };
}

export function getAssessment(assessmentId) {
  return async dispatch => {
    try {
      dispatch({ type: GET_ASSESSMENT_REQUEST });

      const assessment = await assessmentApi.getAssessment(assessmentId);

      dispatch({
        type: GET_ASSESSMENT_SUCCESS,
        payload: {
          data: assessment
        }
      });
    } catch (error) {
      dispatch({ type: GET_ASSESSMENT_FAILURE, payload: { error } });
      throw error;
    }
  };
}

export function selectAssessment(assessmentId) {
  return { type: SELECT_ASSESSMENT, payload: { assessmentId } };
}

export function clearAssessment() {
  return { type: CLEAR_ASSESSMENT };
}
