import React from 'react';

function Logo(props) {
  return (
    <img
      alt="Logo"
      src="/static/logo.png"
      style={{ maxWidth: 140, maxHeight: 'auto', verticalAlign: 'middle' }}
      {...props}
    />
  );
}

export default Logo;
