import { getAllTalents } from 'src/services/talentService';

export const CLEAR_TALENTS_FILTERS = '@talent/clear-talents-filters';
export const UPDATE_TALENTS_FILTER_KEY_VALUE = '@talent/update-talents-filter-key-value';

export const GET_FILTERED_TALENTS_SUCCESS = '@talent/get-talents-success';
export const GET_FILTERED_TALENTS_REQUEST = '@talent/get-talents-request';
export const GET_FILTERED_TALENTS_FAILURE = '@talent/get-talents-failure';

export function updateTalentsFilterKeyValue(key, value) {
  return {
    type: UPDATE_TALENTS_FILTER_KEY_VALUE,
    payload: {
      key,
      value
    }
  };
}

export function clearTalentsFilters() {
  return {
    type: CLEAR_TALENTS_FILTERS,
  };
}

export function getFilteredTalents(params) {
  return async dispatch => {
    try {
      dispatch({ type: GET_FILTERED_TALENTS_REQUEST });

      const filteredTalents = await getAllTalents(params);

      dispatch({
        type: GET_FILTERED_TALENTS_SUCCESS,
        payload: {
          data: filteredTalents.talents || [],
          pagination: filteredTalents.meta || {}
        }
      });
    } catch (error) {
      dispatch({
        type: GET_FILTERED_TALENTS_FAILURE,
        payload: { error }
      });
    }
  };
}
