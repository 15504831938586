import { instance as axiosInstance } from 'src/utils/axios';
import {
  extractAnswersFromSchema,
  parseCorrectAnswerFromSchema
} from 'src/utils/schema';

export const getAssessments = () => new Promise((resolve, reject) => {
  axiosInstance
    .get('/assessment/all')
    .then(response => {
      resolve(response.data.assessments);
    })
    .catch(error => {
      reject(error);
    });
});

export const getAssessment = assessmentId => new Promise((resolve, reject) => {
  axiosInstance
    .get(`/assessment/${assessmentId}`)
    .then(response => {
      const { assessment } = response.data;

      // transform questions since question object contains jsonSchema
      // for the admin module, jsonSchema is unnecessary
      const questions = assessment.questions.map(question => {
        let { answers } = question;
        if (answers) {
          answers = extractAnswersFromSchema(answers);
        }

        let correctAnswer = question.correct_answer;
        if (correctAnswer) {
          // eslint-disable-next-line
          correctAnswer = parseCorrectAnswerFromSchema(
            correctAnswer
          );
        }

        return { ...question, answers, correct_answer: correctAnswer };
      });

      resolve({ ...assessment, questions });
    })
    .catch(error => {
      reject(error);
    });
});

export const createAssessment = assessmentValues => new Promise((resolve, reject) => {
  const {
    questions,
    passingScore,
    roles,
    skills,
    title,
    description,
    level,
    type,
    published
  } = assessmentValues;

  const assessmentRoles = roles.map(role => role.id);
  const assessmentSkills = skills.map(skill => skill.id);
  const orderedQuestions = questions.map((questionId, index) => ({
    questionId,
    order: index + 1
  }));
  const parsedPassingScore = Number(passingScore);

  axiosInstance
    .post('/assessment/create', {
      title,
      description,
      level,
      type,
      questions: orderedQuestions,
      passingScore: parsedPassingScore,
      roles: assessmentRoles,
      skills: assessmentSkills,
      published
    })
    .then(response => {
      resolve(response.data.assessment);
    })
    .catch(error => {
      reject(error);
    });
});

export const editAssessment = async (assessmentId, assessmentValues) => {
  const {
    title,
    description,
    roles,
    skills,
    questions,
    level,
    type,
    passingScore,
    published
  } = assessmentValues;

  const assessmentRoles = { roles: roles.map(role => role.id) };
  const assessmentSkills = { skills: skills.map(skill => skill.id) };
  const orderedQuestions = questions.map((questionId, index) => ({
    questionId,
    order: index + 1
  }));
  const parsedPassingScore = Number(passingScore);

  const assessmentData = {
    title,
    type,
    level,
    description,
    passingScore: parsedPassingScore,
    questions: orderedQuestions,
    published
  };

  try {
    const assessmentResponse = await axiosInstance.put(
      `/assessment/${assessmentId}`,
      assessmentData
    );
    await axiosInstance.put(
      `/assessment/${assessmentId}/updateSkills`,
      assessmentSkills
    );
    await axiosInstance.put(
      `/assessment/${assessmentId}/updateRoles`,
      assessmentRoles
    );

    return {
      assessment: assessmentResponse
    };
  } catch (error) {
    return error;
  }
};

export const getAssessmentFeedbacks = assessmentId => 
  axiosInstance.get(`/v1/analytics/feedback-assessments/${assessmentId}`)
    .then(response => response.data);
