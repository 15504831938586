/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  LOGOUT,
  SILENT_LOGIN
} from 'src/actions/accountActions';

const initialState = {
  isAuthenticated: null,
  profile: {
    first_name: 'Skilledd',
    last_name: 'Admin'
  }
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.isAuthenticated = false;
      });
    }

    case LOGIN_SUCCESS: {
      return produce(state, draft => {
        draft.isAuthenticated = true;
      });
    }

    case LOGIN_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case PROFILE_REQUEST: {
      return produce(state, draft => {
        draft.profile = {
          first_name: 'Skilledd',
          last_name: 'Admin'
        };
      });
    }

    case PROFILE_SUCCESS: {
      const { admin } = action.payload;
      return produce(state, draft => {
        draft.profile = admin;
      });
    }

    case LOGOUT: {
      return produce(state, draft => {
        draft.isAuthenticated = false;
      });
    }

    case SILENT_LOGIN: {
      const { isAuthenticated } = action.payload;

      return produce(state, draft => {
        draft.isAuthenticated = isAuthenticated;
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
