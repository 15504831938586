import * as skillApi from 'src/services/skillService';

export const GET_SKILLS_REQUEST = '@skill/get-skills-request';
export const GET_SKILLS_SUCCESS = '@skill/get-skills-success';
export const GET_SKILLS_FAILURE = '@skill/get-skills-failure';

export const GET_SKILL_REQUEST = '@skill/get-skill-request';
export const GET_SKILL_SUCCESS = '@skill/get-skill-success';
export const GET_SKILL_FAILURE = '@skill/get-skill-failure';

export function getSkills() {
  return async dispatch => {
    try {
      dispatch({ type: GET_SKILLS_REQUEST });

      const skills = await skillApi.getAllSkills();

      dispatch({
        type: GET_SKILLS_SUCCESS,
        payload: {
          data: skills.sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
          })
        }
      });
    } catch (error) {
      dispatch({
        type: GET_SKILLS_FAILURE,
        payload: { error }
      });
    }
  };
}

export function getSkill(skillId) {
  return async dispatch => {
    try {
      dispatch({ type: GET_SKILL_REQUEST });

      const skill = await skillApi.getSkill(skillId);

      dispatch({
        type: GET_SKILL_SUCCESS,
        payload: {
          data: skill
        }
      });
    } catch (error) {
      dispatch({
        type: GET_SKILL_FAILURE,
        payload: { error }
      });
    }
  };
}
