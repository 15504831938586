import React, { lazy, Suspense } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthRoute from 'src/components/AuthRoute';
import GuestRoute from 'src/components/GuestRoute';

function Routes() {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Redirect exact from="/" to="/app" />
        <Route
          exact
          path="/404"
          component={lazy(() => import('src/views/pages/Error404View'))}
        />
        <GuestRoute
          exact
          path="/login"
          component={lazy(() => import('src/views/auth/LoginView'))}
        />
        <Route
          exact
          path="/login-unprotected"
          component={lazy(() => import('src/views/auth/LoginView'))}
        />
        <GuestRoute
          exact
          path="/register"
          component={lazy(() => import('src/views/auth/RegisterView'))}
        />
        <Route
          exact
          path="/register-unprotected"
          component={lazy(() => import('src/views/auth/RegisterView'))}
        />
        <AuthRoute
          path="/app"
          render={props => (
            <DashboardLayout {...props}>
              <Suspense fallback={<LoadingScreen />}>
                <Switch>
                  <Redirect exact from="/app" to="/app/assessments/view" />
                  <Route
                    exact
                    path="/app/assessments/:id/feedbacks"
                    component={lazy(() => import('src/views/assessment-feedback/AssessmentFeedbackView'))}
                  />
                  <Redirect
                    exact
                    from="/app/assessments"
                    to="/app/assessments/view"
                  />

                  <Route
                    exact
                    path="/app/users/add"
                    component={lazy(() => import('src/views/auth/RegisterView'))}
                  />
                  <Route
                    exact
                    path="/app/assessments/view"
                    component={lazy(() => import('src/views/assessment/AssessmentsView'))}
                  />
                  <Route
                    exact
                    path={[
                      '/app/assessments/create',
                      '/app/assessments/edit/:assessmentId'
                    ]}
                    component={lazy(() => import('src/views/assessment/CreateAssessmentView'))}
                  />
                  <Redirect
                    exact
                    from="/app/questions"
                    to="/app/questions/view"
                  />
                  <Route
                    exact
                    path="/app/questions/view"
                    component={lazy(() => import('src/views/question/QuestionsListView'))}
                  />
                  <Route
                    exact
                    path={[
                      '/app/questions/create',
                      '/app/questions/edit/:questionId'
                    ]}
                    component={lazy(() => import('src/views/question/CreateQuestionView'))}
                  />
                  <Redirect exact from="/app/talents" to="/app/talents/view" />
                  <Route
                    exact
                    path="/app/talents/view"
                    component={lazy(() => import('src/views/talents/TalentsView'))}
                  />
                  <Route
                    exact
                    path="/app/talents/view/:talentId"
                    component={lazy(() => import('src/views/talents/TalentsDetailView'))}
                  />
                  <Route
                    exact
                    path="/app/education-resources"
                    component={lazy(() => import('src/views/education-resource/EducationResourceListView'))}
                  />
                  <Route
                    exact
                    path="/app/education-resources/create"
                    component={lazy(() => import('src/views/education-resource/CreateEducationResourceView'))}
                  />
                  <Route
                    exact 
                    path="/app/dashboard/analytics/acquisition"
                    component={lazy(() => import('src/views/dashboard/Acquisition'))}
                  />
                  <Route
                    exact
                    path="/app/dashboard/analytics/assessment"
                    component={lazy(() => import('src/views/dashboard/Assessment'))}
                  />
                  <Route
                    exact
                    path="/app/dashboard/analytics/assessmentconversion"
                    component={lazy(() => import('src/views/dashboard/AssessmentConversion'))}
                  />
                  <Redirect to="/404" />
                </Switch>
              </Suspense>
            </DashboardLayout>
          )}
        />
        <Redirect to="/404" />
      </Switch>
    </Suspense>
  );
}

export default Routes;
